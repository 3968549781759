import React, { Component } from 'react';
import uuidv4 from 'uuid/v4';
import './index.less';

import * as api from '@/axios/api';
import { fact2chart } from '@/tool/fact2vis';
import { isValid } from '@/view/FactView/helper';

const compareTime = new Date('2021-11-2')

export default class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentWillMount() {
        let { sid } = this.props.match.params;

        this.initStory(sid);
    }

    initStory = (sid) => {
        api.queryStoriesBySid(sid)
            .then(response => {
                let story = response.story.story;
                if (!story) return;

                const { json, ...extra } = story;
                const { facts, relations, data, schema, showSuggestion } = JSON.parse(json);
                story = { ...extra, facts, relations, data, schema, showSuggestion };
                story.MaxSignificanceId = 0
                let events = story.facts.map(function (fact, i, facts) {
                    //significance最高的显示第一帧chart画面
                    if (fact.significance > facts[story.MaxSignificanceId].significance) {
                        story.MaxSignificanceId = i; //增加字段，保存用来显示第一帧的fact id
                    }
                    return {
                        id: uuidv4(),
                        index: i,
                        fact: fact,
                        relation: story.relations[i],
                    }
                })
                events.filter(fact => {
                    if (!fact.aggregated) {
                        return true
                    } else {
                        return fact.aggregated && fact.aggregatedFact
                    }
                })
                story.events = events;
                let averageFactScore = (events.map(x => x.fact.score).reduce((a, b) => a + b, 0)) / events.length;
                if (!averageFactScore) averageFactScore = 0;
                story.averageFactScore = averageFactScore;

                this.setState({ story });
            })
            .catch(e => {
                console.error(e);
            })
    }

    render() {
        const { story = {} } = this.state;
        const { events = [] } = story;
        const maxSignificanceEvent = { ...events[story.MaxSignificanceId] } || {};
        maxSignificanceEvent.id = uuidv4();

        return (
            <div className='list_wrapper' dataRef={7}>
                {events && events.length > 0 && [maxSignificanceEvent, ...events].map((item, index) => {
                    return (
                        <div key={index} className='storyInfoDiv'>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#fff', width: '100%', height: 160 - 37.7 }}>
                                <div style={{ transformOrigin: 'top', transform: 'scale(0.7)', maxHeight: '105px', paddingTop: '15px' }}>
                                    <Item
                                        event={item}
                                        storyData={story.data}
                                        schema={story.schema}
                                        schemaFilterLocal={story.schemaFilterLocal}
                                        dataresLocal={story.dataresLocal}
                                        chartSize={'small'}
                                        showSuggestion={story.showSuggestion}
                                        oldStory={compareTime > new Date(story?.time?.split('T')[0])}
                                    />
                                </div>
                            </div>
                            <div className='descriptionDiv'>
                                <div className='description'>
                                    {index === 0 ? story.description : item.fact.generatedScript}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

class Item extends Component {
    render() {
        let { event, storyData, chartSize, schema, showSuggestion, dataresLocal, schemaFilterLocal, oldStory } = this.props;
        let vis;
        let datares;
        let specData;
        datares = storyData;
        if (dataresLocal && dataresLocal !== []) datares = dataresLocal
        if (schemaFilterLocal && schemaFilterLocal !== []) {
            specData = {
                'schema': schemaFilterLocal,
                'showSuggestion': showSuggestion
            }
        }
        else {
            specData = {
                'schema': schema,
                'showSuggestion': showSuggestion
            }
        }
        if (!event) return (<div>no fact</div>);
        if (isValid(event.fact)) {
            vis = fact2chart(specData, event.id, event.fact, datares, chartSize, false, oldStory);
        }

        return (
            <div>{vis}</div>
        );
    }
}