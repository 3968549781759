import * as req from './base';
import config from '@/axios/config';
import axios from 'axios';
/**
 * 用户认证 相关接口
 * @param 
 */
export async function checkToken() {
    const data = await req.request({
        url: `${config.url.checkToken}`,
        method: 'post',
        param: {},
        data: {}
    });
    return data;
}

export async function loginadmintest() {
    const data = await req.request({
        url: `${config.url.loginadmintest}`,
        method: 'post',
        param: {},
        data: {}
    });
    return data;
}

export async function accessToken(body) {
    const data = await req.request({
        url: `${config.url.accessToken}`,
        method: 'post',
        param: {},
        data: body
    });
    return data;
}
export async function logout() {
    const data = await req.request({
        url: `${config.url.logout}`,
        method: 'post',
        param: {},
        data: {}
    });
    return data;
}


/**
 * Stories 相关接口
 * @param 
 */
export async function createStory(body) {
    const data = await req.request({
        url: `${config.url.stories}`,
        method: 'post',
        param: {},
        data: body
    });
    return data;
}
export async function updateStory(sid, body) {
    const data = await req.request({
        url: `${config.url.stories}/${sid}`,
        method: 'put',
        param: {},
        data: body
    });
    return data;
}
export async function queryTopRatedDataStories(params) {
    const data = await req.request({
        url: config.url.stories,
        method: 'get',
        param: params
    });
    return data;
}
export async function getStoryRate(sid) {
    const data = await req.request({
        url: `${config.url.stories}/${sid}/rating`,
        method: 'get'
    });
    return data;
}
export async function getSharedlink(sid) {
    const data = await req.request({
        url: `${config.url.stories}/${sid}/sharelink`,
        method: 'get'
    });
    return data;
}
export async function getPublic(uuid) {
    const data = await req.request({
        url: `${config.url.public}/${uuid}`,
        method: 'get'
    });
    return data;
}
export async function queryStoriesByUserId(uid,params) {
    const data = await req.request({
        url: `${config.url.queryStoriesByUserId}/${uid}/stories`,
        method: 'get',
        param:params
    });
    return data;
}
export async function queryStoriesBySid(sid) {
    const data = await req.request({
        url: `${config.url.queryStoriesBySid}/${sid}`,
        method: 'get'
    });
    return data;
}
export async function generateStoriesBySid(sid) {
    const data = await req.request({
        url: `${config.url.stories}/generateImage/${sid}`,
        method: 'get'
    });
    return data;
}
//My comment pages
export async function queryCommentWithStory(sid, comment) {
    const data = await req.request({
        url: `${config.url.queryStoriesBySid}/${sid}`,
        method: 'get'
    });
    comment.story = data.story.story
    return comment;
}
export async function queryStoriesByDatasetId(did) {
    const data = await req.request({
        url: `${config.url.queryStoriesByDatasetId}/${did}/stories`,
        method: 'get'
    });
    return data;
}
export async function viewStory(sid) {
    const data = await req.request({
        url: `${config.url.stories}/${sid}/view`,
        method: 'post',
        param: {},
        data: {}
    });
    return data;
}
export async function deleteStory(sid) {
    const data = await req.request({
        url: `${config.url.stories}/${sid}`,
        method: 'delete'
    });
    return data;
}

export async function updateReply(sid, cid, body) {
    const data = await req.request({
        url: `${config.url.stories}/${sid}/comments/${cid}`,
        method: 'put',
        param: {},
        data: body
    });
    return data;
}
export async function deleteReply(sid, cid) {
    const data = await req.request({
        url: `${config.url.stories}/${sid}/comments/${cid}`,
        method: 'delete'
    });
    return data;
}
/**
 * Datasets 相关接口
 * @param 
 */
export async function queryPublishedDatasets(params) {
    const data = await req.request({
        url: config.url.queryPublicDatasets,
        method: 'get',
        param: params
    });
    return data;
}
export async function queryDatasetByDid(did) {
    const data = await req.request({
        url: `${config.url.dataset}/${did}`,
        method: 'get'
    });
    return data;
}
export async function queryDatasetsByUserId(uid,params) {
    const data = await req.request({
        url: `${config.url.queryDatasetsByUserId}/${uid}/datasets`,
        method: 'get',
        param:params
    });
    return data;
}
export async function deleteDatasetById(did) {
    const data = await req.request({
        url: `${config.url.deleteDatasetById}/${did}`,
        method: 'delete'
    });
    return data;
}
export async function postADataset(body) {
    const data = await req.request({
        url: `${config.url.postADataset}`,
        method: 'post',
        param: {},
        data: body
    });
    return data;
}
export async function updateADataset(did, body) {
    const data = await req.request({
        url: `${config.url.dataset}/${did}`,
        method: 'put',
        param: {},
        data: body
    });
    return data;
}
export async function uploadData(formData) {
    return new Promise((reslove, reject) => {
        axios({
            method: "post",
            url: `${config.url.uploadData}`,
            config: {
                "headers": {
                    'Content-Type': 'multipart/form-data'
                },
            },
            data: formData
        }).then((response) => {
            if (response.status >= 400) {
                reject();
            } else if (response.status === 200 || response.status === 201 || response.status === 204) {
                reslove(response.data);
            } else {
                reject();
            }
        }).catch(error => {
            reject();
            //  message.error('error message');
        })
    })
}


/**
 * 评论 相关接口
 * @param 
 */
export async function queryCommentsByStoryId(sid, param) {
    const data = await req.request({
        url: `${config.url.queryCommentsByStoryId}/${sid}/comments`,
        method: 'get',
        param,
    });
    return data;
}

export async function rating(sid, body) {
    const data = await req.request({
        url: `${config.url.rating}/${sid}/rating`,
        method: 'post',
        param: {},
        data: body
    });
    return data;
}
export async function queryCommentsByUid(uid) {
    const data = await req.request({
        url: `${config.url.users}/${uid}/comments`,
        method: 'get',
    });
    return data;
}
/**
 * 发布评论
 * @param 
 */
export async function postComments(sid, body) {
    const data = await req.request({
        url: `${config.url.comments}/${sid}/comments`,
        method: 'post',
        param: {},
        data: body
    });
    return data;
}
/**
 *  回复评论
 * @param 
 */
export async function replyComments(sid, cid, body) {
    const data = await req.request({
        url: `${config.url.comments}/${sid}/comments/${cid}/replies`,
        method: 'post',
        param: {},
        data: body
    });
    return data;
}
/**
 * 点赞评论
 * @param 
 */
export async function likeComments(sid, cid, body) {
    const data = await req.request({
        url: `${config.url.comments}/${sid}/comments/${cid}/likes`,
        method: 'post',
        param: {},
        data: body
    });
    return data;
}

/**
 * 数据|故事|评论 操作行为记录 API
 * @param 
 */
export async function activitiesApi(uid, body) {
    const data = await req.requestWithoutCredentials({
        url: `${config.url.activitiesApi}/${uid}/activities`,
        method: 'post',
        param: {},
        data: body
    });
    return data;
}


export function generate(url, data) {
    return axios({
        method: "post",
        url: url,
        config: {
            "headers": {
                'Content-Type': 'application/json; charset=utf-8'
            },
        },
        data: data
    })
}

export function factScoring(filename, fact,filters, method) {
    return axios({
        "method": "POST",
        "url": config.url.factScoring,
        "headers": {
            "Content-Type": "application/json; charset=utf-8"
        },
        "data": {
            "file_name": filename,
            "fact": fact,
            "filters":filters,
            "method": method,
        }
    })
}



// 用于插值生成facts API
export function interpolate(url, data) {
    return axios({
        method: "post",
        url: url,
        config: {
            "headers": {
                'Content-Type': 'application/json; charset=utf-8'
            },
        },
        data: data
    })
}
