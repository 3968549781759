import React, { Component } from 'react';
import { fact2chart } from '@/tool/fact2vis';
import { isValid } from '@/view/FactView/helper';

export default class EventCard extends Component {
    render() {
        let { event, storyData, isHovered, currentPlayEvent, chartSize, schema, showSuggestion, dataresLocal, schemaFilterLocal, oldStory } = this.props;
        let vis;
        let datares
        let specData
        datares = storyData
        if (dataresLocal && dataresLocal !== []) datares = dataresLocal
        if (schemaFilterLocal && schemaFilterLocal !== []) {
            specData = {
                "schema": schemaFilterLocal,
                "showSuggestion": showSuggestion
            }
        }
        else {
            specData = {
                "schema": schema,
                "showSuggestion": showSuggestion
            }
        }

        if (!event) return (<div>no fact</div>)
        // console.log("EventCard", storyData)
        if (!isHovered) {  //significance最高的显示第一帧chart画面
            if (isValid(event.fact)) {
                vis = fact2chart(specData, event.id, event.fact, datares, chartSize, false, oldStory);
            }
        } else if (isHovered && currentPlayEvent) { //使用currentPlayFact轮播
            if (isValid(currentPlayEvent.fact)) {
                vis = fact2chart(specData, currentPlayEvent.id, currentPlayEvent.fact, datares, chartSize, false, oldStory);
            }
        }
        return (
            <div>{vis}</div>
        )
    }
}