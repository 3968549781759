import React, { Component } from 'react';
import { Spin, Table, Pagination, Divider, Icon, Button, Badge } from 'antd';
import moment from 'moment';
import * as api from '@/axios/api';
import '../../ExploreDatasetsPage/ExploreDatasets.less';
import '@/components/Datasets/DatasetsTable/DatasetsTable.less';

export default class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            pageSize: 50,
            total: 0
        }
        this.ref = React.createRef();
    }

    componentWillMount() {
        this.queryStories(this.state.current, this.state.pageSize);
    }

    componentDidMount() {
        if (this.ref.current) {
            this.setState({ width: this.ref.current.offsetWidth });
        }
    }

    queryStories = (current, pageSize) => {
        let params = {
            per_page: pageSize,
            page: current - 1,
            sort: 'time'
        }
        this.setState({ spinning: true });
        api.queryTopRatedDataStories(params)
            .then((response) => {
                this.setState({ spinning: false });
                if (response) {
                    const { stories = [], totalCount } = response;

                    if (current === 1) {
                        this.setState({
                            current,
                            pageSize,
                            total: totalCount,
                            list: stories
                        });
                    } else {
                        this.setState({
                            current,
                            pageSize,
                            list: stories
                        });
                    }
                }
            }).catch(e => {
                console.error(e);
                this.setState({ spinning: false, current, pageSize, total: 0 });
            });
    }

    generateImage = (sid, isBatch) => {
        return new Promise((resolve, reject) => {
            if (sid) {
                this.setState({ [`loading_${sid}`]: '生成中' });
                api.generateStoriesBySid(sid)
                    .then(response => {
                        this.setState({ [`loading_${sid}`]: '已生成' });
                        if (response && response.success && !isBatch) {
                            this.queryStories(this.state.current, this.state.pageSize);
                        }

                        resolve();
                    }).catch(e => {
                        console.error(e);
                        resolve();
                        this.setState({ [`loading_${sid}`]: '生成失败' });
                    });
            } else {
                resolve();
            }
        });
    }

    generateBatchImage = (sids) => {
        if (Array.isArray(sids) && sids.length) {
            this.setState({ btnLoading: true });
            let loadings = {};
            sids.forEach(sid => {
                loadings[`loading_${sid}`] = '待生成';
            });
            this.setState({ ...loadings });
            // 异步并发创建缩略图
            // Promise.all(sids.map(sid => this.generateImage(sid, true)))
            //     .then(response => {
            //         this.setState({ btnLoading: false, selectedRowKeys: [] });
            //         this.queryStories(this.state.current, this.state.pageSize);
            //     }).catch(e => {
            //         console.error(e);
            //         this.setState({ btnLoading: false });
            //     });
            // 同步串行创建缩略图
            let p = undefined;
            for (let i = 0; i < sids.length; i++) {
                if (i === 0) {
                    p = this.generateImage(sids[i], true);
                } else {
                    p = p.then(() => {
                        return this.generateImage(sids[i], true);
                    });
                }
            }
            p.then(() => {
                this.setState({ btnLoading: false, selectedRowKeys: [] });
                this.queryStories(this.state.current, this.state.pageSize);
            });
        }
    }

    isGenerate = () => {
        let result = false;

        Object.keys(this.state).forEach(key => {
            if (key && key.indexOf('loading_') !== -1) {
                if (this.state[key] === '生成中') {
                    result = true;
                }
            }
        });

        return result;
    }

    onChange = (current, pageSize) => {
        this.queryStories(current, pageSize);
    }

    onShowSizeChange = (current, pageSize) => {
        this.queryStories(1, pageSize);
    }

    render() {
        const { btnLoading = false, spinning = false, current, pageSize, total, list = [], width = 0, selectedRowKeys = [] } = this.state;
        const { intl } = this.props;
        const isGenerate = this.isGenerate();
        const columns = [
            {
                title: '名称',
                dataIndex: 'title',
                key: 'title',
                width: width - 60 - 100 * 2 - 100 - 140 - 120 * 2
            }, {
                title: '评分人数',
                dataIndex: 'num_of_rates',
                key: 'num_of_rates',
                align: 'center',
                width: 100
            }, {
                title: '分享人数',
                dataIndex: 'num_of_shares',
                key: 'num_of_shares',
                align: 'center',
                width: 100
            }, {
                title: '创建者',
                dataIndex: 'username',
                key: 'username',
                align: 'center',
                width: 100,
                render: (text, record) => {
                    const { user = {} } = record;

                    return user.username;
                }
            }, {
                title: '创建时间',
                dataIndex: 'time',
                key: 'time',
                align: 'center',
                width: 140,
                render: (text) => {
                    if (text) {
                        return moment(text, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                    }
                    return text;
                }
            }, {
                title: '是否已生成缩略图',
                dataIndex: 'images',
                key: 'images',
                align: 'center',
                width: 120,
                render: (text, record) => {
                    if (typeof this.state[`loading_${record.sid}`] === 'string') {
                        return this.state[`loading_${record.sid}`];
                    } else {
                        const { images } = record;

                        return !!images ? '是' : '否';
                    }
                }
            }, {
                title: '生成',
                dataIndex: 'option',
                key: 'option',
                align: 'center',
                width: 120,
                render: (text, record) => (
                    <div style={{ cursor: 'pointer' }}>
                        {(typeof this.state[`loading_${record.sid}`] === 'string' ? this.state[`loading_${record.sid}`] === '生成中' : this.state[`loading_${record.sid}`]) ?
                            <Icon type='loading' style={{ fontSize: 20 }} /> :
                            <Icon type=' ' className='generateIcon' onClick={() => isGenerate ? {} : this.generateImage(record.sid)}></Icon>
                        }
                    </div>
                )
            }
        ];

        return (
            <div ref={this.ref} className='ExploreDatasetsPageContent'>
                <div className='badge_wrapper' style={{ padding: '10px 24px 10px 0' }}>
                    <Badge count={selectedRowKeys.length} style={{ backgroundColor: '#f28b30' }}>
                        <Button disabled={btnLoading ? false : isGenerate} loading={btnLoading} onClick={() => this.generateBatchImage(selectedRowKeys)}>批量生成</Button>
                    </Badge>
                </div>
                <div className='datasetsTable' style={{ backgroundColor: 'white', marginTop: '40px' }}>
                    <Spin tip={intl.get('Loading')} spinning={spinning}>
                        <Table
                            rowSelection={{
                                selectedRowKeys,
                                onChange: (selectedRowKeys, selectedRows) => {
                                    this.setState({ selectedRowKeys });
                                }
                            }}
                            columns={columns}
                            dataSource={list}
                            style={{ width: '100%' }}
                            rowKey={record => record.sid}
                            size={'small'}
                            pagination={false}
                            tableLayout='fixed'
                            onChange={this.onChange}
                            scroll={{ x: 719 }} />
                    </Spin>
                </div>
                <div className='DataSetsFootPage'>
                    <Divider />
                    <Pagination size='small' current={current} pageSize={pageSize} total={total} showSizeChanger pageSizeOptions={['50', '100', '150', '200']} onChange={this.onChange} onShowSizeChange={this.onShowSizeChange} />
                </div>
            </div>
        );
    }
}