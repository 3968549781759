/* eslint no-eval: 0 */
import React from "react";
import LayoutType from '@/constant/LayoutType';
import * as api from '@/axios/api';
import Fact from '@/model/fact';
import generateIteratively from '@/network/generateIteratively';
import { Select, message } from 'antd';
// import { getFactChartType } from '@/tool/fact2vis';
import config from '@/axios/config';
import './VisOptionView.less';
import {genFactSentence,genSubtitle} from '../../../sentencer/index';
const { Option } = Select;

export default class VisOptionView extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            cannotChangeData: false,
            isCanSave: true
        }
    }
    componentWillMount() {
        if (this.props.isLoadedInCreatePage && !this.props.isEditStory) {
            // this.generateIteratively()
            this.initiallyGenerate()
        }
    }
    initiallyGenerate = () => {
        // this.generate(config.url.generateRandomlyNew).then(response =>{
        this.generate(config.url.generateRandomly).then(response => {
            //生成故事结束
            this.props.leaveCreatePage(false) //important ************* reset state
            this.props.loadingGeneratingView(false) //生成后主动关闭生成框
            this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, sid: null })
            const facts = response.data.story.facts;
            // const facts = response.data.story.facts.facts;
            let relations=[];
            let tempFacts = [];
            for (let factDict of facts) {
                let fact = new Fact(
                    factDict['type'],
                    factDict['measure'],
                    factDict['subspace'],
                    factDict['groupby'],
                    factDict['focus'],
                    factDict['parameter'],
                    "", // chart
                    factDict['score'],
                    factDict['information'],
                    factDict['significance']
                )
                tempFacts.push(fact);
                relations.push('none')
            }
            tempFacts.map((item,index) => {
                // console.log(item);
                item.generatedScript = genFactSentence(item, this.props.currentLocale)
                item.generatedSubtitle = genSubtitle(item,this.props.currentLocale)
                return null
            })
            this.props.generateStory(tempFacts.slice(),relations, 1);
        })
    }
    generate = (url) => {
        return new Promise(async (resolve, reject) => {
            let fileName = this.props.fileName;
            this.props.generateStory([], [], '');
            this.props.loadingGeneratingView(true)
            const { storyParameter } = this.props;
            let data = {
                file_name: fileName,
                max_story_length: storyParameter.maxStoryLength,
                // similarty:"w2v",
                // start_fact:"",
                // end_fact:"",
            }
            const response = await api.generate(url, data)
            // const response = await api.generate(config.url.generateRandomly, data)
            // const response = await api.interpolate(url, data)
            if (response.data.fail) {
                this.props.loadingGeneratingView(false)
                message.info(response.data.fail)
                this.setState({
                    isCanSave: false
                })
                reject();
            }
            if (response.data.error) {
                this.props.loadingGeneratingView(false)
                //message.info(response.data.error)
                this.setState({
                    isCanSave: false
                })
                reject();
            }
            resolve(response)
        })
    }

    // generateIteratively = async () => {
    //     if (this.props.isShowGenerate) return
    //     this.props.generateStory([], [], '')
    //     this.props.loadingGeneratingView(true)
    //     //const { storyParameter, fileName, method } = this.props;
    //     const { storyParameter, fileName } = this.props;
    //     const { rewardWeight } = this.props;
    //     let tempFacts = [];
    //     //let tempRelations = [];
    //     let tree = ""
    //     let max_iteration = storyParameter.maxStoryLength;
    //     let information = 0;
    //     while ((tempFacts.length !== storyParameter.maxStoryLength || information < storyParameter.information) && max_iteration !== 0) {
    //         // let time = parseInt((storyParameter.maxStoryLength - index) * unitTime);
    //         let time = storyParameter.timeLimit;
    //         const response = await generateIteratively(fileName, storyParameter.maxStoryLength, storyParameter.information, 'sig', time, rewardWeight, tree)
    //         if (response.data.fail) {
    //             this.props.loadingGeneratingView(false)
    //             message.info(response.data.fail)
    //             this.setState({
    //                 isCanSave: false
    //             })
    //             return;
    //         }
    //         if (response.data.error) {
    //             this.props.loadingGeneratingView(false)
    //             //message.info(response.data.error)
    //             this.setState({
    //                 isCanSave: false
    //             })
    //             return;
    //         }
    //         const facts = response.data.story.facts;
    //         tree = response.data.tree;
    //         tempFacts = [];
    //         //tempRelations = [];
    //         // let factDict = response.data.facts[response.data.facts.length - 1]
    //         // console.log(response.data.facts)
    //         if (facts.length < 1) {
    //             max_iteration -= 1;
    //         } else {
    //             information = 0;
    //             for (let factDict of facts) {
    //                 let fact = new Fact(
    //                     factDict['type'],
    //                     factDict['measure'],
    //                     factDict['subspace'],
    //                     factDict['groupby'],
    //                     factDict['focus'],
    //                     factDict['parameter'],
    //                     "", // chart
    //                     factDict['score'],
    //                     factDict['information'],
    //                     factDict['significance']
    //                 )
    //                 information += factDict['information'];
    //                 //fact.chart = getFactChartType(fact, this.props.data);
    //                 tempFacts.push(fact);
    //             }
    //             //console.log("iteration: " + max_iteration + ", length: " + tempFacts.length + ", information: " + information);
    //             this.props.generateStory(tempFacts.slice(), response.data.story.relations, response.data.story.coverage)
    //             max_iteration -= 1
    //         }
    //     }
    //     //生成故事结束
    //     this.props.leaveCreatePage(false) //important ************* reset state
    //     this.props.loadingGeneratingView(false) //生成后主动关闭生成框
    //     this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, sid: null })

    // }
    
    generateIteratively = async () => {
        if (this.props.isShowGenerate) return
        this.props.generateStory([], [], '')
        this.props.loadingGeneratingView(true)
        //const { storyParameter, fileName, method } = this.props;
        const { fileName} = this.props;
        let tempFacts = [];
        let relations=[];
        let tree = ""
        let startFact=""
        let endFact=""
       while(true){
            // let time = parseInt((storyParameter.maxStoryLength - index) * unitTime);
            const response = await generateIteratively(fileName, 6, '', startFact,endFact, tree, "MCTS","nofilter",'w2v',1)
            if (response.data.fail) {
                this.props.loadingGeneratingView(false)
                message.info(response.data.fail)
                this.setState({
                    isCanSave: false
                })
                return;
            }
            if (response.data.error) {
                this.props.loadingGeneratingView(false)
                //message.info(response.data.error)
                this.setState({
                    isCanSave: false
                })
                return;
            }
            let tempdata
            if(response.data.story) tempdata=response.data
            else tempdata=eval('('+response.data+')')
            const facts = tempdata.story.facts.facts;
            if(facts===null){
                break;
            }
            tree = tempdata.tree;
            startFact= tempdata.story.facts.facts[0]
            endFact= tempdata.story.facts.facts[tempdata.story.facts.facts.length-1]
            relations=[];
            tempFacts = [];
            
            for (let factDict of facts) {
                let fact = new Fact(
                    factDict['type'],
                    factDict['measure'],
                    factDict['subspace'],
                    factDict['groupby'],
                    factDict['focus'],
                    factDict['parameter'],
                    "", // chart
                    factDict['score'],
                    factDict['information'],
                    factDict['significance']
                )
                fact.generatedScript = genFactSentence(fact, this.props.intl.options.currentLocale)
                fact.generatedSubtitle =genSubtitle(fact,this.props.intl.options.currentLocale)
                tempFacts.push(fact)
                relations.push('none');
            }
            //console.log("iteration: " + max_iteration + ", length: " + tempFacts.length + ", information: " + information);
            this.props.generateStory(tempFacts.slice(), relations, 1)
            if(tempdata.flag) break;
            
        }
        //生成故事结束
        this.props.leaveCreatePage(false) //important ************* reset state
        this.props.loadingGeneratingView(false) //生成后主动关闭生成框
        this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, sid: null })

    }

    changeLayout = (value) => {
        this.props.changeLayOutType(value)
        this.props.changeLayOutTypeListener(value)
    }

    render() {
        let { layoutType, userEnd, isLoadedInCommentPage, intl } = this.props;
        let isMobileEnd = userEnd.startsWith('iphone')
        return (
            <div >
                <div className='VisOptionViewDiv' style={{ display: isLoadedInCommentPage && isMobileEnd ? "none" : 'flex' }}>
                    <Select
                        className='optionsDiv'
                        defaultValue={layoutType || LayoutType.STORYLINE_WEB}
                        value={layoutType || LayoutType.STORYLINE_WEB}
                        onChange={this.changeLayout}
                    >
                        <Option value={LayoutType.STORY_TEXT}>{intl.get("summary")}</Option>
                        <Option value={LayoutType.TABLE} style={{ display: isMobileEnd ? 'none' : 'block' }}>{intl.get("table")}</Option>
                        <Option value={LayoutType.STORYLINE_WEB} style={{ display: isMobileEnd ? 'none' : 'block' }}>{intl.get("storyline")}</Option>
                        <Option value={LayoutType.SLIDER_MOBILE}>{intl.get("mobile")}</Option>
                        <Option value={LayoutType.FACTSHEET} style={{ display: isMobileEnd ? 'none' : 'block' }}>{intl.get("factsheet")}</Option>
                    </Select>


                </div>

            </div>
        )
    }
}