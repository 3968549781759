import React, { Component } from 'react';
import SingleCardView from './SingleCardView';
import ShareModalView from '@/components/ShareModalView/index';
import { Spin } from 'antd';
import _ from 'lodash';
import './CardList.less';
import '@/pages/common.less';

export default class CardList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMyStories: this.props.isMyStories || false,
            stories: this.props.stories,
            isShareModalVisible: false,
            visible: false
        }
    }
    componentWillMount() {
        if (this.props.isMyStories) {
            let firstNoDisplayStory = _.cloneDeep(this.props.stories)[0]
            this.props.stories.unshift(firstNoDisplayStory)
            this.setState({
                stories: this.props.stories
            })
        }
    }
    componentWillReceiveProps(newProps) {
        if (newProps.stories !== this.state.stories) {
            if (newProps.isMyStories) {
                let firstNoDisplayStory = _.cloneDeep(newProps.stories)[0]
                newProps.stories.unshift(firstNoDisplayStory)
                this.setState({
                    stories: newProps.stories
                })
            } else {
                this.setState({
                    stories: newProps.stories
                })
            }
        }
    }

    creatStory = () => {
        this.props.history.push('/index/story/select/dataset')
    }
    deleteAStory = (index) => {
        //判断是第几个
        let newstories = _.cloneDeep(this.state.stories)
        newstories.splice(index, 1)
        this.setState({
            stories: newstories
        })
    }
    shareAStory = () => {
        this.setState({
            visible: true
        })
    }
    closeShareModal = () => {
        this.setState({
            visible: false
        })
    }
    render() {
        const { isWindowUpScroll, isCardCenter } = this.props;
        const { stories, isMyStories, intl, visible } = this.state;

        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: 'white', justifyContent: isCardCenter ? 'center' : '' }} className='offsetStoryWrapper' >
                {
                    stories && stories.length === 0
                        ?
                        <div className='addStoryWrapper creatStory' key={"story_add"}>
                            <div className='addImage' onClick={this.creatStory}></div>
                        </div>
                        :
                        stories.map((story, index) => {
                            return index === 0 && isMyStories ?
                                <div className='addStoryWrapper creatStory' key={"story_" + index}>
                                    <div className='addImage' onClick={this.creatStory}></div>
                                </div> :
                                story ?
                                    <SingleCardView
                                        key={'story_' + index}
                                        storyIndex={index}
                                        story={story}
                                        {...this.props}
                                        updateStoryOnDelete={(idx) => this.deleteAStory(idx)}
                                        onShareAStory={this.shareAStory}
                                    />
                                    : null
                        })
                }
                {isWindowUpScroll && (
                    <div className="loading-container">
                        <Spin tip={intl.get("Loading")} />
                    </div>
                )}
                <ShareModalView visible={visible} closeShareModal={this.closeShareModal} {...this.props} />
            </div>
        )
    }
}