 let calliopeService = 'service.datacalliope.com';
// let calliopeService = 'calliope-service.idvxlab.com';
let applicationUrl='https://datacalliope.com';
// let applicationUrl = 'http://calliope-dev.idvxlab.com';

let urlPrefix = process.env.NODE_ENV === 'production' ? `https://${calliopeService}:8002/api/v1` : 'http://localhost:7003/api/v1'
let uploadDataPrefix = process.env.NODE_ENV === 'production' ? `https://${calliopeService}:8002` : 'http://localhost:7003'
let generationUrlPrefix = process.env.NODE_ENV === 'production' ? `https://${calliopeService}:8002` : 'http://localhost:7003';
// let urlPrefix = process.env.NODE_ENV === 'production' ? `https://${calliopeService}:8002/api/v1` : 'http://localhost:7003/api/v1'
// let uploadDataPrefix = process.env.NODE_ENV === 'production' ? `https://${calliopeService}:8002` : 'http://localhost:6008'
// let generationUrlPrefix = process.env.NODE_ENV === 'production' ? `https://${calliopeService}:8002` : 'http://localhost:6010';
let activitiesPrefix = process.env.NODE_ENV === 'production' ? `https://${calliopeService}:8002/log/v1` : 'http://localhost:7003/log/v1';

// let activitiesPrefix = process.env.NODE_ENV === 'production' ? `https://${calliopeService}:8002/log/v1` : 'http://10.10.14.118:7001/log/v1';
let publicPrefix = process.env.NODE_ENV === 'production' ? applicationUrl : 'http://localhost:3000';
let client_id = '033338f2-02a8-44d4-8d54-5173b4a864f9';
// let client_id = '6dcd0f8b-6990-4d69-9018-29b3351aa6ff';
let accountIp = `https://account.datacalliope.com`
let logoutRedirectUrlIp = `https://service.datacalliope.com:8001`

const config = {
    url: {
        applicationUrl: applicationUrl,
        //share
        //shareLinkPrefix: `${applicationUrl}:7000/`,
        publicPrefix: publicPrefix,
        //public
        // publicUrl: `${publicPrefix}/#/share/`,
        //login
        loginadmintest: `${urlPrefix}/loginadmintest`,
        loginRedirectUrl: `${accountIp}/#/login?response_type=code&client_id=${client_id}&grant_type=authorization_code&redirect_uri=${applicationUrl}`,
        logoutRedirectUrl: `${applicationUrl}/#/logout`,
        authenUrl: accountIp,
        accessToken: `${urlPrefix}/accesstoken`,
        uploadData: `${uploadDataPrefix}/upload`,
        userImage: `${logoutRedirectUrlIp}/img`,
        checkToken: `${urlPrefix}/checktoken`,
        //logout
        logout: `${urlPrefix}/logout`,
        logoutAuthSystem: `${logoutRedirectUrlIp}/logout`,

        //story
        stories: `${urlPrefix}/stories`,
        public: `${urlPrefix}/public`,
        queryStoriesByUserId: `${urlPrefix}/users`,
        queryStoriesByDatasetId: `${urlPrefix}/datasets`,
        queryStoriesBySid: `${urlPrefix}/stories`,
        rating: `${urlPrefix}/stories`,
        //comment
        queryCommentsByStoryId: `${urlPrefix}/stories`,
        comments: `${urlPrefix}/stories`,
        //dataset
        queryPublicDatasets: `${urlPrefix}/datasets`,
        queryDatasetsByUserId: `${urlPrefix}/users`,
        deleteDatasetById: `${urlPrefix}/datasets`,
        postADataset: `${urlPrefix}/datasets`,
        dataset: `${urlPrefix}/datasets`,
        uploadDataPrefix: uploadDataPrefix,
        //activities
        users: `${urlPrefix}/users`,
        activitiesApi: `${activitiesPrefix}/users`,
        //story generation
        factScoring: `${generationUrlPrefix}/fact`,
        generateStory: `${generationUrlPrefix}/generate-v2`,
        generateIteratively: `${generationUrlPrefix}/generate-v3`,
        generateRandomly: `${generationUrlPrefix}/generate`,

        //interpolate
        interpolateMCTS:`${generationUrlPrefix}/interpolateMCTS`,
        interpolateQL:`${generationUrlPrefix}/interpolateQL`,
        generateRandomlyNew: `${generationUrlPrefix}/randomgenerate`,
    }

}

export default config