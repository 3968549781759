import React, { Component } from "react";
import { Empty } from 'antd';
import RelatedStoryList from './RelatedStoryList';
import * as api from '@/axios/api';
import activitiesCode from '@/axios/activitiesCode';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';

export default class RelatedStoriesView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingRelatedStory: false,
            isRelatedStoriesLoadedAll: false,
            relatedStories: null,
            nextPage: 0
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(nextProps.story, this.props.story)) {
            this.queryRelatedStory(nextProps.story)
            return true
        }
        if (!_.isEqual(nextState.relatedStories, this.state.relatedStories)) {
            this.setState({
                relatedStories: nextState.relatedStories
            })
            return true
        }
        if (nextProps.isMobilephoneEnd !== this.props.isMobilephoneEnd) {
            return true
        }
        if (nextState.isRelatedStoriesLoadedAll !== this.state.isRelatedStoriesLoadedAll) {
            return true
        }
        return false
    }

    queryRelatedStory = (story, page) => {
        this.setState({
            loadingRelatedStory: true,
            isRelatedStoriesLoadedAll: false
        })
        this.story = story
        const { title, dataset } = story;
        const { keywords } = dataset
        let keywordsList = keywords.split(';');
        let keywordTx = '';
        keywordsList.map(keyword => {
            keywordTx += ' ' + keyword
            return keyword
        })
        let params = {
            keywords: `${title}${keywordTx}`,
            per_page: 5,
            page: page || 0,
            sort: 'time'
        }
        const { relatedStories } = this.state
        api.queryTopRatedDataStories(params).then((response) => {
            //load All
            if (response.stories && response.stories.length === 0) {
                this.setState({
                    isRelatedStoriesLoadedAll: true,
                    loadingRelatedStory: false
                })
                return
            }
            //更新
            let currentRelatedStories = []
            if (page) currentRelatedStories = _.cloneDeep(relatedStories)
            let newRelatedStories = currentRelatedStories.concat(response.stories)

            let storyId = this.story && this.story.sid
            //剔除自身
            let newStoriesList = newRelatedStories.filter((story) => story.sid !== storyId)
            //console.log("剔除自身", newStoriesList)
            newStoriesList && this.processChartData(newStoriesList)

            //activitiesApi
            let uid = this.props.userInfo.uid
            let boby = {
                action: activitiesCode.searchStories,
                objectid: -1,
                meta: `${title}${keywordTx}`,
            }
            api.activitiesApi(uid, boby).then(response => {
                //   console.log("activitiesApi",response)
            })
        })
    }

    processChartData = (storyList) => {
        let newStoriesList = storyList.map(story => {
            let { json, ...extra } = story;
            if (json) {
                json = JSON.parse(json);
            }
            const { facts = [], relations = [], data = [], schema = [], showSuggestion } = json || {};
            story = { ...extra, facts, relations, data, schema, showSuggestion }
            return story
        })
        newStoriesList.map(story => {
            story.MaxSignificanceId = 0
            let events = story.facts.map(function (fact, i, facts) {
                //significance最高的显示第一帧chart画面
                if (fact.significance > facts[story.MaxSignificanceId].significance) {
                    story.MaxSignificanceId = i;
                }
                return {
                    id: uuidv4(),
                    index: i,
                    fact: fact,
                    relation: story.relations[i],
                }
            })
            events.filter(fact => {
                if (!fact.aggregated) {
                    return true
                } else {
                    return fact.aggregated && fact.aggregatedFact
                }
            })
            story.events = events;
            let averageFactScore = (events.map(x => x.fact.score).reduce((a, b) => a + b, 0)) / events.length;
            if (!averageFactScore) averageFactScore = 0;
            story.averageFactScore = averageFactScore;
            return story
        })
        this.setState({
            relatedStories: newStoriesList,
            loadingRelatedStory: false
        })

    }

    loadMoreRelatedStory = () => {
        const { nextPage } = this.state
        let newPage = nextPage + 1
        this.queryRelatedStory(this.story, newPage)
        this.setState({
            nextPage: newPage
        })
    }

    render() {
        const { relatedStories, isRelatedStoriesLoadedAll, loadingRelatedStory } = this.state
        //console.log("relatedStories view render", relatedStories)
        return (
            <div>
                {
                    relatedStories ?
                        relatedStories.length === 0 ?
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            :
                            <RelatedStoryList
                                isLoadedAll={isRelatedStoriesLoadedAll}
                                loadingRelatedStory={loadingRelatedStory}
                                stories={relatedStories}
                                onViewARelatedStoryListener={this.props.onViewARelatedStoryListener}
                                onLoadMore={() => this.loadMoreRelatedStory()}
                                {...this.props} />
                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
            </div>
        )
    }
}