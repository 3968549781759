import React, { Component } from "react";
import { Rate, message } from 'antd';
import * as api from '@/axios/api';
import activitiesCode from '@/axios/activitiesCode';
import EventCard from '@/components/CardListView/EventCardView/EventCard';
import StoryTitle from './StoryTitleView/StoryTitle';
let moment = require('moment');

let playTimer = null;
const compareTime = new Date("2021-11-2")
export default class SingleCardView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPlayEvent: null,
            currentPlayIndex: 0,
            index: -1,
            isShareModalVisible: false,
            isHovered: false
        }
    }

    componentWillReceiveProps() {
        //console.log("componentWillReceiveProps", this.props.story.title)
    }

    hoverCard = () => {
        if (playTimer) {
            //清除未执行的逻辑，重新执行下一次逻辑，无论之前是否处理完
            clearInterval(playTimer)
        }
        const { isHovered } = this.state
        if (isHovered) return
        const { story } = this.props
        let { events = [], images } = story;
        if (images) {
            try {
                images = JSON.parse(images);
            } catch (e) {
                images = [];
                console.error(e);
            }
        }
        let factsLen = events.length || images?.length;
        let playIndex = 0;
        if (factsLen) {
            //立即执行
            this.setState({
                currentPlayEvent: story.events[playIndex],
                currentPlayIndex: playIndex,
                isHovered: true
            })
            playIndex++;
            //每秒播放一张
            playTimer = setInterval(() => {
                if (playIndex >= factsLen) {
                    playIndex = 0;
                }
                this.setState({
                    currentPlayEvent: story.events[playIndex],
                    currentPlayIndex: playIndex,
                })
                playIndex++;
            }, 1200)
        }
    }

    moveOut = () => {
        this.setState({
            isHovered: false
        })
        clearInterval(playTimer)
    }

    reviewAuthor = (e, user) => {
        if (e.stopPropagation) {
            e.stopPropagation();
        } else {
            e.cancelBubble = true;
        }
        const { username, uid } = user
        this.props.history.push({
            pathname: `/index/existingStoriesCreatedBy/${uid}/${username}`
        })
    }

    deleteAStory = () => {
        const { story, storyIndex, userInfo } = this.props;
        let sid = story.sid
        api.deleteStory(sid).then(() => {
            this.props.updateStoryOnDelete(storyIndex)
            //activitiesApi
            let uid = userInfo.uid
            let body = {
                action: activitiesCode.deleteStory,
                objectid: sid,
                meta: sid.toString(),
            }
            api.activitiesApi(uid, body).then(response => {
            })
        })
    }

    OnEditAStory = (story) => {
        if (compareTime > new Date(story.time.split("T")[0])) {
            message.info(this.props.intl.get("edit story error"))
            return
        }
        this.props.history.push(`/index/story/edit/${story.sid}`)
    }

    click = (sid) => {
        this.props.history.push({
            pathname: `/index/commentAStory/${sid}`,
        })
    }

    viewStory = (index) => {
        //console.log("viewStory", this.props.stories[index])
        this.props.history.push({
            pathname: 'View a story',
            query: {
                story: this.props.stories[index]
            }
        })
    }

    componentWillUnmount() {
        clearInterval(playTimer)
    }

    render() {
        const { story, intl } = this.props
        let { images } = story;
        if (images) {
            try {
                images = JSON.parse(images);
            } catch (e) {
                images = [];
                console.error(e);
            }
        }
        const hasImage = images && images.length > 0;
        const { currentPlayEvent, currentPlayIndex, isHovered } = this.state

        return (
            <div className='storyWrapper'
                onMouseEnter={() => this.hoverCard()}
                onMouseLeave={() => this.moveOut()}
                onClick={() => this.click(story.sid)} >
                <div className='storyInfoDiv' style={hasImage ? { height: 'auto' } : {}}>
                    <div className='topDiv' style={hasImage ? { height: 'auto' } : {}}>
                        <div style={{ height: "36.7px", width: '100%' }}>
                            <StoryTitle
                                story={story}
                                storyTitle={story && story.title}
                                OnDeleteAStory={() => this.deleteAStory()}
                                OnEditAStory={() => this.OnEditAStory(story)}
                                isScroll={isHovered}
                                {...this.props}>
                            </StoryTitle>
                        </div>
                        <div style={{ backgroundColor: '#EBEDF2', width: '160px', height: '1px' }} ></div>
                        {hasImage ?
                            <div>
                                <img src={images[currentPlayIndex]} width={170} height={173.296875} />
                            </div> :
                            <div className='storyFactWrapper'>
                                <EventCard
                                    event={story.events && story.events && story.events[story.MaxSignificanceId]}
                                    isHovered={isHovered}
                                    currentPlayEvent={currentPlayEvent}
                                    key={story.events && story.events[story.MaxSignificanceId] && story.events[story.MaxSignificanceId].id}
                                    index={story.events && story.events[story.MaxSignificanceId] && story.events[story.MaxSignificanceId].index}
                                    storyData={story.data}
                                    schema={story.schema}
                                    schemaFilterLocal={story.schemaFilterLocal}
                                    dataresLocal={story.dataresLocal}
                                    chartSize={"small"}
                                    showSuggestion={story.showSuggestion}
                                    oldStory={compareTime > new Date(story.time.split("T")[0])}
                                />
                            </div>
                        }
                    </div>
                    {!hasImage &&
                        <div className='descriptionDiv'>
                            {
                                isHovered
                                    ?
                                    <span className='description'>{currentPlayEvent && currentPlayEvent.fact.generatedScript}</span>
                                    :
                                    <span className='description'>
                                        {story.description}
                                    </span>
                            }

                        </div>
                    }
                </div>
                <div className='storyRateInfo'>
                    <div className='rowDiv' style={{ height: '13px', lineHeight: '13px', marginBottom: '2px' }}>
                        {
                            (story && story.rating) ?
                                <Rate
                                    allowHalf
                                    disabled={true}
                                    value={(parseInt(story.rating.toFixed(1).split('.')[1]) > 5) ?
                                        parseInt(`${story.rating.toFixed(1).split('.')[0]}`) + 1
                                        : parseInt(story && story.rating.toFixed(1).split('.')[1]) === 0 ? parseInt(story && story.rating.toFixed(1)) : parseFloat(`${story && story.rating.toFixed(1).split('.')[0]}.5`)}
                                    style={{ fontSize: '12px', color: '#F28B30' }} />
                                :
                                <Rate value={0} disabled={true} style={{ fontSize: '12px', color: '#F28B30' }} />
                        }
                        <span className='sharesAndRates'>{story.num_of_rates} {intl.get("story.card.rates")},{story.num_of_shares} {intl.get("story.card.shares")}</span>
                    </div>
                    <span className='createdTxt ' onClick={(e) => this.reviewAuthor(e, story.user)}>{intl.get("story.card.Created by")} <span className='authorName'>{story.user.username}</span> </span>
                    <span className='createdTxt'>{intl.get("story.card.Created on")} {moment(story.time).format('MMM.Do,YYYY')}</span>
                </div>
            </div>
        )
    }
}